<template>
  <section class="section">
    <div class="container ist-container">
      <h1 class="title is-4">
        New Simulation
      </h1>
      <div style="margin: 4em 0 5em 0;">
        <StepIndicator
          v-model="activeStep"
          :labels="steps"
          :warnings="stepWarnings"
        />
      </div>
      <!-- ### Step: Setup ### -->
      <div v-if="activeStepName === 'setup'">
        <h2 class="title is-3">
          Setup
        </h2>
        <div class="columns">
          <div class="column is-6">
            <!-- Title -->
            <Field
              id="s0-title"
              v-model="title"
              label="Title"
              :validate="highestVisitedStep > 0"
            />
          </div>
        </div>
        <div class="columns more-space">
          <div class="column">
            <!-- Drug: Library/Upload -->
            <FieldRadio
              id="s0-drug-mode"
              v-model="drugMode"
              label="GnRH agonist"
              help="A known GnRH agonist can be selected from the library or custom input can be provided for own compound."
              :options="drugModeOptions"
            />
          </div>
          <div class="column">
            <!-- Drug from Library -->
            <FieldSelect
              v-if="drugMode.value=='library'"
              id="s0-drug-select"
              v-model="drug"
              label="Select GnRH agonist from library"
              help="For the GnRH agonists present in the library, pharmacokinetic model structures and parameters for each formulation as well as pharmacodynamic model parameters are set to default values. In addition, the tool provides the option to customize the fields' values."
              :options="drugLibraryOptions"
            />
          </div>
        </div>
        <div
          v-if="drugMode.value=='library'"
          class="columns more-space"
        >
          <div class="column is-6">
            <!-- Sustained Release Formulation -->
            <FieldSelect
              id="s1-formulation-select"
              v-model="formulationChoice"
              label="Select sustained release formulation"
              :options="formulationLibraryOptions"
            />
            <p
              class="is-size-7"
              style="margin-top: -0.5em; margin-bottom: 3em;"
            >
              Formulation options as in
              <span
                v-if="drug.value == 'triptorelin'"
              >Romero et  al. 2012</span>
              <span
                v-else
              >Lim et al. 2015</span>
            </p>
            <FieldCheckbox
              v-if="formulationChoice.value!=='custom'"
              id="s0-customize-checkbox"
              v-model="customizeChoice"
              label="Customize pharmacokinetic and pharmacodynamic settings"
              :options="customizeOptions"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- ### Step: Pharmacokinetic Settings ### -->
      <div v-else-if="activeStepName === 'pharmacokinetic_settings'">
        <h2 class="title is-3">
          Pharmacokinetic Settings
        </h2>
        <div class="columns">
          <div class="column is-6">
            <!-- Administration Type -->
            <FieldSelect
              id="pharmacokinetic-administration-type"
              v-model="administrationTypeChoice"
              label="Administration type"
              :options="administrationTypeOptions"
            />
          </div>
        </div>
        <template v-if="administrationTypeChoice.value === 'extravascular'">
          <div class="columns">
            <div class="column">
              <h2
                class="title is-4"
                style="margin-top: 1em;"
              >
                Absorption
              </h2>
              <p
                v-if="warningTotalF"
                style="margin-bottom: 1.5em;"
              >
                <font-awesome-icon
                  icon="exclamation-triangle"
                  class="has-text-warning"
                />
                <span
                  class="has-text-warning"
                  style="margin-left: 0.25em;"
                >Warning:</span>
                The sum of the fractions Finf, F1 and F2 should not exceed 1.
              </p>
              <FieldCheckbox
                id="pharmacokinetic-process"
                v-model="processChoice"
                label="Add process"
                :options="processOptions"
              />
              <template v-if="processChoice.value.zero">
                <h3
                  class="title is-5"
                  style="margin-top: 2em;"
                >
                  Zero-order process
                </h3>
                <Field
                  id="pharmacokinetic-Dinf"
                  v-model="Dinf"
                  label="Infusion duration D<sub>inf</sub>&nbsp;(days)"
                  :validate="highestVisitedStep > 1"
                  vpositive-or-zero
                />
                <Field
                  id="pharmacokinetic-Finf"
                  v-model="Finf"
                  label="Bioavailability F<sub>inf</sub>&nbsp;(-)"
                  help="Typical value or population value"
                  :validate="highestVisitedStep > 1"
                  :vmin="0"
                  :vmax="1"
                />
                <Field
                  id="pharmacokinetic-tlaginf"
                  v-model="tlaginf"
                  label="Latency time t<sub>lag,inf</sub>&nbsp;(days)"
                  help="Typical value or population value in days"
                  :validate="highestVisitedStep > 1"
                  vpositive-or-zero
                />
              </template>
              <template v-if="processChoice.value.first">
                <h3
                  class="title is-5"
                  style="margin-top: 2em;"
                >
                  First-order process
                </h3>
                <FieldSelect
                  id="pharmacokinetic-compartments"
                  v-model="firstOrderCompartmentChoice"
                  label="Number of first-order depot compartments"
                  :options="firstOrderCompartmentOptions"
                />
              </template>
            </div>
            <div class="column">
              <figure
                v-if="processChoice.value.zero || processChoice.value.first"
                class="image"
              >
                <img :src="absorptionFigure">
              </figure>
            </div>
          </div>
          <div
            v-if="processChoice.value.first"
            class="columns"
          >
            <div class="column is-6">
              <h4
                class="title is-6"
                style="margin-top: 1em;"
              >
                Depot compartment 1
              </h4>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="pharmacokinetic-KA1-pop"
                    v-model="KA1"
                    help="Typical value or population value in 1/days"
                    label="First-order rate constant of absorption K<sub>A1</sub>&nbsp;(1/days)"
                    :validate="highestVisitedStep > 1"
                    vpositive-or-zero
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="pharmacokinetic-KA1-IIV"
                    v-model="IIV_KA1"
                    label="IIV (%)"
                    placeholder="-"
                    help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                    optional
                    vpositive-or-zero
                    :validate="highestVisitedStep > 1"
                  />
                </div>
              </div>
              <Field
                id="pharmacokinetic-F1-pop"
                v-model="F1"
                label="Bioavailibility F<sub>1</sub>&nbsp;(-)"
                help="Typical value or population value"
                :validate="highestVisitedStep > 1"
                :vmin="0"
                :vmax="1"
              />
              <FieldRadio
                id="pharmacokinetic-lag-transit"
                v-model="depotOneLagTransitChoice"
                :options="depotOneLagTransitOptions"
              />
              <Field
                v-if="depotOneLagTransitChoice.value === 'tlag'"
                id="pharmacokinetic-tlag1"
                v-model="tlag1"
                label="Latency time t<sub>lag1</sub>&nbsp;(days)"
                help="Typical value or population value in days"
                :validate="highestVisitedStep > 1"
                vpositive-or-zero
              />
              <Field
                v-if="depotOneLagTransitChoice.value === 'transit'"
                id="pharmacokinetic-n1-pop"
                v-model="n1"
                label="Number of transit compartments n<sub>1</sub>&nbsp;(-)"
                help="Typical value or population value"
                :validate="highestVisitedStep > 1"
                vpositive-or-zero
              />
              <Field
                v-if="depotOneLagTransitChoice.value === 'transit'"
                id="pharmacokinetic-mt1-pop"
                v-model="mt1"
                label="Mean transit time mt<sub>1</sub>&nbsp;(days)"
                help="Typical value or population value in days"
                :validate="highestVisitedStep > 1"
                vpositive-or-zero
              />
            </div>
            <div
              v-if="firstOrderCompartmentChoice.value === '2'"
              class="column is-6"
            >
              <h4
                class="title is-6"
                style="margin-top: 1em;"
              >
                Depot compartment 2
              </h4>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="pharmacokinetic-KA2-pop"
                    v-model="KA2"
                    help="Typical value or population value in 1/days"
                    label="First-order rate constant of absorption K<sub>A2</sub>&nbsp;(1/days)"
                    :validate="highestVisitedStep > 1"
                    vpositive-or-zero
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="pharmacokinetic-KA2-IIV"
                    v-model="IIV_KA2"
                    label="IIV (%)"
                    placeholder="-"
                    help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                    optional
                    :validate="highestVisitedStep > 1"
                    vpositive-or-zero
                  />
                </div>
              </div>
              <Field
                id="pharmacokinetic-F2-pop"
                v-model="F2"
                label="Bioavailibility F<sub>2</sub>&nbsp;(-)"
                help="Typical value or population value"
                :validate="highestVisitedStep > 1"
                :vmin="0"
                :vmax="1"
              />
              <FieldRadio
                id="pharmacokinetic-lag-transit-two"
                v-model="depotTwoLagTransitChoice"
                :options="depotOneLagTransitOptions"
              />
              <Field
                v-if="depotTwoLagTransitChoice.value === 'tlag'"
                id="pharmacokinetic-tlag2"
                v-model="tlag2"
                label="Latency time t<sub>lag2</sub>&nbsp;(days)"
                help="Typical value or population value in days"
                :validate="highestVisitedStep > 1"
                vpositive-or-zero
              />
              <Field
                v-if="depotTwoLagTransitChoice.value === 'transit'"
                id="pharmacokinetic-n2-pop"
                v-model="n2"
                label="Number of transit compartments n<sub>2</sub>&nbsp;(-)"
                help="Typical value or population value"
                :validate="highestVisitedStep > 1"
                vpositive-or-zero
              />
              <Field
                v-if="depotTwoLagTransitChoice.value === 'transit'"
                id="pharmacokinetic-mt2-pop"
                v-model="mt2"
                label="Mean transit time mt<sub>2</sub>&nbsp;(days)"
                help="Typical value or population value in days"
                :validate="highestVisitedStep > 1"
                vpositive-or-zero
              />
            </div>
          </div>
        </template>
        <h2
          class="title is-4"
          style="margin-top: 1em;"
        >
          Disposition
        </h2>
        <div class="columns">
          <div class="column">
            <FieldSelect
              id="pharmacokinetic-compartments-disposition"
              v-model="dispositionCompartmentChoice"
              label="Number of compartments"
              :options="dispositionCompartmentOptions"
            />
            <h4
              class="title is-6"
              style="margin-top: 2em;"
            >
              Central compartment
            </h4>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-CL"
                  v-model="CL"
                  help="Typical value or population value in L/day"
                  label="Apparent total clearance CL&nbsp;(L/day)"
                  :validate="highestVisitedStep > 1"
                  vpositive-or-zero
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-CL-IIV"
                  v-model="IIV_CL"
                  label="IIV (%)"
                  placeholder="-"
                  help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If IIV value is not known, field can be left empty."
                  optional
                  vpositive-or-zero
                  :validate="highestVisitedStep > 1"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-V"
                  v-model="V"
                  help="Typical value or population value in L"
                  label="Apparent volume of distribution of the central compartment V&nbsp;(L)"
                  :validate="highestVisitedStep > 1"
                  vpositive
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-V-IIV"
                  v-model="IIV_V"
                  label="IIV (%)"
                  placeholder="-"
                  help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                  optional
                  vpositive-or-zero
                  :validate="highestVisitedStep > 1"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <figure class="image">
              <img :src="dispositionFigure">
            </figure>
          </div>
        </div>
        <div
          v-if="dispositionCompartmentChoice.value > 1"
          class="columns"
        >
          <div class="column is-6">
            <h4
              class="title is-6"
              style="margin-top: 1em;"
            >
              Peripheral compartment
            </h4>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-Q1"
                  v-model="Q1"
                  help="Typical value or population value in L/day"
                  label="Distribution clearance between the central and the peripheral compartment Q<sub>1</sub>&nbsp;(L/day)"
                  :validate="highestVisitedStep > 1"
                  vpositive-or-zero
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-Q1-IIV"
                  v-model="IIV_Q1"
                  label="IIV (%)"
                  placeholder="-"
                  help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                  optional
                  :validate="highestVisitedStep > 1"
                  vpositive-or-zero
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-V1"
                  v-model="V1"
                  help="Typical value or population value in L"
                  label="Volume of distribution of peripheral compartment V<sub>1</sub>&nbsp;(L)"
                  :validate="highestVisitedStep > 1"
                  vpositive
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-V1-IIV"
                  v-model="IIV_V1"
                  label="IIV (%)"
                  placeholder="-"
                  help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                  optional
                  :validate="highestVisitedStep > 1"
                  vpositive-or-zero
                />
              </div>
            </div>
          </div>
          <div
            v-if="dispositionCompartmentChoice.value > 2"
            class="column is-6"
          >
            <h4
              class="title is-6"
              style="margin-top: 1em;"
            >
              Deep peripheral compartment
            </h4>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-Q2"
                  v-model="Q2"
                  help="Typical value or population value in L/day"
                  label="Distribution clearance between the central and the deep peripheral compartment Q<sub>2</sub>&nbsp;(L/day)"
                  vpositive-or-zero
                  :validate="highestVisitedStep > 1"
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-Q2-IIV"
                  v-model="IIV_Q2"
                  label="IIV (%)"
                  placeholder="-"
                  help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                  optional
                  vpositive-or-zero
                  :validate="highestVisitedStep > 1"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-V2"
                  v-model="V2"
                  help="Typical value or population value in L"
                  label="Volume of distribution of deep peripheral compartment V<sub>2</sub>&nbsp;(L)"
                  vpositive
                  :validate="highestVisitedStep > 1"
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-V2-IIV"
                  v-model="IIV_V2"
                  label="IIV (%)"
                  placeholder="-"
                  help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                  optional
                  vpositive-or-zero
                  :validate="highestVisitedStep > 1"
                />
              </div>
            </div>
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- ### Step: Pharmacodynamic Settings ### -->
      <div v-else-if="activeStepName === 'pharmacodynamic_settings'">
        <h2 class="title is-3">
          Pharmacodynamic Settings
        </h2>
        <div class="columns">
          <div class="column is-6">
            <h4 class="title is-5">
              Drug-related parameters
            </h4>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacodynamic-KD"
                  v-model="KDB"
                  help="Typical value or population value in ng/mL"
                  label="Receptor equilibrium dissociation constant of the GnRH agonist - K<sub>D</sub>&nbsp;(ng/mL)"
                  :validate="highestVisitedStep > 2"
                  vpositive-or-zero
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacodynamic-KD-IIV"
                  v-model="IIV_KDB"
                  label="IIV (%)"
                  placeholder="-"
                  help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If IIV value is not known, field can be left empty."
                  optional
                  :validate="highestVisitedStep > 2"
                  vpositive-or-zero
                />
              </div>
            </div>
            <FieldCheckbox
              id="pharmacodynamic-advanced"
              v-model="advancedChoice"
              :options="advancedOptions"
            />
          </div>
        </div>
        <div v-if="advancedChoice.value.enabled">
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column is-9">
                  <h4 class="title is-5">
                    System parameters
                  </h4>
                  <Field
                    id="pharmacodynamic-DR-50"
                    v-model="E50"
                    help="Down-regulation parameter at 50% of the maximum effect. Typical value or population value"
                    label="Parameter driving the down regulation process D<sub>R_50</sub>&nbsp;(-)"
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="pharmacodynamic-E50-IIV"
                    v-model="IIV_E50"
                    label="IIV (%)"
                    placeholder="-"
                    help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                    optional
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="pharmacodynamic-TST0"
                    v-model="TST0"
                    help="Testosterone concentration at baseline. Typical value or population value in ng/mL"
                    label="Baseline testosterone level TST<sub>0</sub>&nbsp;(ng/mL)"
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="pharmacodynamic-TST0-IIV"
                    v-model="IIV_TST0"
                    label="IIV (%)"
                    placeholder="-"
                    help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                    optional
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="pharmacodynamic-KIN"
                    v-model="KIN"
                    help="Typical value or population value in ng/mL/day"
                    label="Zero-order rate production of testosterone independent from gonadotropins k<sub>IN</sub>&nbsp;(ng&middot;(mL&middot;day)<sup>-1</sup>)"
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="pharmacodynamic-KIN-IIV"
                    v-model="IIV_KIN"
                    label="IIV (%)"
                    placeholder="-"
                    help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                    optional
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="pharmacodynamic-KDR"
                    v-model="KREO"
                    help="Typical value or population value in 1/day"
                    label="First-order rate constant of degradation k<sub>D_R</sub>&nbsp;(1/day)"
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="pharmacodynamic-kDR-IIV"
                    v-model="IIV_KREO"
                    label="IIV (%)"
                    placeholder="-"
                    help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                    optional
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="pharmacodynamic-AGN"
                    v-model="AGN"
                    help="Typical value or population value"
                    label="Ratio between the endogenous agonist concentration and its receptor equilibrium dissociation constant AGN&nbsp;(-)"
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="pharmacodynamic-AGN-IIV"
                    v-model="IIV_AGN"
                    label="IIV (%)"
                    placeholder="-"
                    help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                    optional
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="pharmacodynamic-KDT"
                    v-model="KDTT"
                    help="Typical value or population value in 1/day"
                    label="First-order rate constants of testosterone degradation k<sub>D_T</sub>&nbsp;(1/day)"
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="pharmacodynamic-KDT-IIV"
                    v-model="IIV_KDTT"
                    label="IIV (%)"
                    placeholder="-"
                    help="Inter-Individual Variability (IIV) is expressed through the coefficient of variation and is assumed to follow a lognormal distribution. If the IIV value is not known, field can be left empty."
                    optional
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="pharmacodynamic-GAM1"
                    v-model="GAM1"
                    label="Hill coefficient γ&nbsp;(-)"
                    :validate="highestVisitedStep > 2"
                    vpositive-or-zero
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <figure class="image">
                <img src="../assets/PDscheme.png">
              </figure>
              <p>
                C<sub>drug</sub>, serum concentrations of the GnRH agonist;
                RT, total receptors;
                RT<sub>0</sub>, total receptors at baseline;
                TST, serum concentration of testosterone;
                TST<sub>0</sub>, baseline testosterone level;
                K<sub>D</sub>, receptor equilibrium dissociation constant of the GnRH agonist;
                F<sub>RAC</sub>, fraction of activated receptors;
                F<sub>RAC0</sub>, fraction of activated receptors at baseline;
                D<sub>R</sub>, down-regulation process;
                D<sub>R_50</sub>, the value that elicits a 50% maximal reduction in k<sub>S_R</sub> for a given amount of total receptors;
                k<sub>S_R</sub>, zero-order rate constant of receptor synthesis;
                k<sub>D_R</sub>, first-order rate constant of degradation;
                k<sub>S_T</sub>, zero-order rate constant of testosterone synthesis;
                k<sub>D_T</sub>, first-order rate constant of testosterone degradation;
                k<sub>IN</sub>, zero-order rate production of testosterone independent from gonadotropins;
                AGN, ratio between the endogenous agonist concentration and its receptor equilibrium dissociation constant;
                FDB, feedback;
                γ, Hill coefficient.
              </p>
            </div>
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- ### Step: Trial Design ### -->
      <div v-else-if="activeStepName === 'trial_design'">
        <h2 class="title is-3">
          Trial Design
        </h2>
        <div class="columns">
          <div class="column is-6">
            <Field
              id="s1-population"
              v-model="population"
              label="Number of patients"
              help="Number of patients of the virtual population."
              :validate="(workflow === 'simple' && highestVisitedStep > 1) || (workflow === 'advanced' && highestVisitedStep > 3)"
              :vmin="1"
              :vmax="10000"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <FieldRadio
              id="s2-dosing-mode"
              v-model="dosingMode"
              label="Dosing"
              :options="dosingModeOptions"
            />
            <Field
              id="s2-study-duration"
              v-model="studyDuration"
              label="Study duration (days)"
              :validate="(workflow === 'simple' && highestVisitedStep > 1) || (workflow === 'advanced' && highestVisitedStep > 3)"
              vpositive
            />
            <Field
              id="s2-castration-limit"
              v-model="castrationLimit"
              label="Testosterone castration concentration limit (ng/ml)"
              help="Testosterone level under which castration is achieved. Default value is 0.5 ng/ml."
              :validate="(workflow === 'simple' && highestVisitedStep > 1) || (workflow === 'advanced' && highestVisitedStep > 3)"
              vpositive
            />
          </div>
          <div class="column">
            <Concentrations
              v-if="dosingMode.value=='manual'"
              v-model="dosingStr"
              :concentrations="dosingData"
              :validate="(workflow === 'simple' && highestVisitedStep > 1) || (workflow === 'advanced' && highestVisitedStep > 3)"
              @concentrationsUpdate="handleConcentrationsUpdate"
            />
            <UploadCsv
              v-if="dosingMode.value=='upload'"
              v-model="dosingUpload"
              :headers="dosingUploadHeaders"
              ref-name="new-scheduling"
              :example-file="administrationTypeChoice.value === 'intravenous' ? 'schedule_tinf.csv' : 'schedule.csv'"
              :positive-columns="['amt']"
              help="The columns of the CSV file are:
• group: scheduling number
• time: day of injection; the study starts at day 0
• amt: amount of injected drug in mg
• tinf (only for intravenous administration): duration of infusion in days; in case of a bolus injection, insert 0"
              label="Upload file with dosing schedules for multiple doses"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- ### Step: Run ### -->
      <div v-else>
        <h2 class="title is-3">
          Summary
        </h2>
        <SummaryItem
          label="Title"
          :item="title"
        />
        <SummaryItem
          label="Drug"
          :item="drugMode.value=='library' ? capitalise(drug) : { value: 'Custom', ok: true }"
        />
        <SummaryItem
          label="Sustained release formulation"
          :item="formulation"
        />
        <div
          v-if="workflow === 'advanced'"
          id="summary-pharmacokinetics"
        >
          <div
            v-if="administrationTypeChoice.value === 'extravascular'"
            id="summary-pharmacokinetics-absorption"
          >
            <h4
              class="title is-4"
              style="margin-top: 0.5em; margin-bottom: 0.5em;"
            >
              Absorption
            </h4>
            <SummaryItem
              label="Number of depot compartments"
              :item="numberAbsorptionCompartments"
            />
            <table
              v-if="numberAbsorptionCompartments.value > 0"
              class="table ist-table"
              style="margin-bottom: 2em; margin-top: 1.5em;"
            >
              <thead>
                <tr>
                  <th />
                  <th
                    v-if="processChoice.value.zero"
                    colspan="3"
                  >
                    Zero-order process
                  </th>
                  <th
                    v-if="processChoice.value.first"
                    :colspan="depotOneLagTransitChoice.value === 'transit' ? 4 : 3"
                  >
                    Depot 1
                  </th>
                  <th
                    v-if="processChoice.value.first && firstOrderCompartmentChoice.value == 2"
                    :colspan="depotTwoLagTransitChoice.value === 'transit' ? 4 : 3"
                  >
                    Depot 2
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td />
                  <template v-if="processChoice.value.zero">
                    <td>D<sub>inf</sub> (day)</td>
                    <td>F<sub>inf</sub> (-)</td>
                    <td>t<sub>lag,inf</sub> (day)</td>
                  </template>
                  <template v-if="processChoice.value.first">
                    <td>K<sub>A1</sub> (1/day)</td>
                    <td v-if="depotOneLagTransitChoice.value !== 'transit'">
                      t<sub>lag1</sub> (day)
                    </td>
                    <template v-else>
                      <td>n<sub>1</sub></td>
                      <td>mt<sub>1</sub> (day)</td>
                    </template>
                    <td>F<sub>1</sub> (-)</td>
                  </template>
                  <template v-if="processChoice.value.first && firstOrderCompartmentChoice.value == 2">
                    <td>K<sub>A2</sub> (1/day)</td>
                    <td v-if="depotTwoLagTransitChoice.value !== 'transit'">
                      t<sub>lag2</sub> (day)
                    </td>
                    <template v-else>
                      <td>n<sub>2</sub></td>
                      <td>mt<sub>2</sub> (day)</td>
                    </template>
                    <td>F<sub>2</sub> (-)</td>
                  </template>
                </tr>
                <tr>
                  <td>Population</td>
                  <template v-if="processChoice.value.zero">
                    <td>{{ Dinf.value }}</td>
                    <td>{{ Finf.value }}</td>
                    <td>{{ tlaginf.value }}</td>
                  </template>
                  <template v-if="processChoice.value.first">
                    <td>{{ KA1.value }}</td>
                    <td v-if="depotOneLagTransitChoice.value !== 'transit'">
                      {{ tlag1.value }}
                    </td>
                    <template v-else>
                      <td>{{ n1.value }}</td>
                      <td>{{ mt1.value }}</td>
                    </template>
                    <td>{{ F1.value }}</td>
                  </template>
                  <template v-if="processChoice.value.first && firstOrderCompartmentChoice.value == 2">
                    <td>{{ KA2.value }}</td>
                    <td v-if="depotTwoLagTransitChoice.value !== 'transit'">
                      {{ tlag2.value }}
                    </td>
                    <template v-else>
                      <td>{{ n2.value }}</td>
                      <td>{{ mt2.value }}</td>
                    </template>
                    <td>{{ F2.value }}</td>
                  </template>
                </tr>
                <tr>
                  <td>IIV (%)</td>
                  <template v-if="processChoice.value.zero">
                    <td colspan="3" />
                  </template>
                  <template v-if="processChoice.value.first">
                    <td>{{ IIV_KA1.value }}</td>
                    <td :colspan="depotOneLagTransitChoice.value === 'transit' ? 3 : 2" />
                  </template>
                  <template v-if="processChoice.value.first && firstOrderCompartmentChoice.value == 2">
                    <td>{{ IIV_KA2.value }}</td>
                    <td :colspan="depotTwoLagTransitChoice.value === 'transit' ? 3 : 2" />
                  </template>
                </tr>
              </tbody>
            </table>
            <p v-else>
              No process added.
            </p>
            <p
              v-if="warningTotalF"
              style="margin-bottom: 1.5em;"
            >
              <font-awesome-icon
                icon="exclamation-triangle"
                class="has-text-warning"
              />
              <span
                class="has-text-warning"
                style="margin-left: 0.25em;"
              >Warning:</span>
              The sum of the fractions Finf, F1 and F2 should not exceed 1.
            </p>
          </div>
          <div id="summary-pharmacokinetics-disposition">
            <h4
              class="title is-4"
              style="margin-top: 0.5em; margin-bottom: 0.5em;"
            >
              Disposition
            </h4>
            <SummaryItem
              label="Number of compartments"
              :item="dispositionCompartmentChoice"
            />
            <table
              class="table ist-table"
              style="margin-bottom: 2em; margin-top: 1.5em;"
            >
              <thead>
                <tr>
                  <th />
                  <th
                    colspan="2"
                  >
                    Central compartment
                  </th>
                  <th
                    v-if="dispositionCompartmentChoice.value > 1"
                    colspan="2"
                  >
                    Peripheral compartment 1
                  </th>
                  <th
                    v-if="dispositionCompartmentChoice.value > 2"
                    colspan="2"
                  >
                    Peripheral compartment 2
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td />
                  <td>CL (L/day)</td>
                  <td>V (L)</td>
                  <template v-if="dispositionCompartmentChoice.value > 1">
                    <td>Q<sub>1</sub> (L/day)</td>
                    <td>V<sub>1</sub> (L)</td>
                  </template>
                  <template v-if="dispositionCompartmentChoice.value > 2">
                    <td>Q<sub>2</sub> (L/day)</td>
                    <td>V<sub>2</sub> (L)</td>
                  </template>
                </tr>
                <tr>
                  <td>Population</td>
                  <td>{{ CL.value }}</td>
                  <td>{{ V.value }}</td>
                  <template v-if="dispositionCompartmentChoice.value > 1">
                    <td>{{ Q1.value }}</td>
                    <td>{{ V1.value }}</td>
                  </template>
                  <template v-if="dispositionCompartmentChoice.value > 2">
                    <td>{{ Q2.value }}</td>
                    <td>{{ V2.value }}</td>
                  </template>
                </tr>
                <tr>
                  <td>IIV (%)</td>
                  <td>{{ IIV_CL.value }}</td>
                  <td>{{ IIV_V.value }}</td>
                  <template v-if="dispositionCompartmentChoice.value > 1">
                    <td>{{ IIV_Q1.value }}</td>
                    <td>{{ IIV_V1.value }}</td>
                  </template>
                  <template v-if="dispositionCompartmentChoice.value > 2">
                    <td>{{ IIV_Q2.value }}</td>
                    <td>{{ IIV_V2.value }}</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="workflow === 'advanced'"
          id="summary-pharmacodynamics"
        >
          <h3
            class="title is-4"
            style="margin-top: 1.5em;"
          >
            Drug parameters
          </h3>
          <SummaryItem
            label="GnRH agonist receptor equilibrium dissociation constant K<sub>D</sub> (ng/mL)"
            :item="KDB"
          />
          <SummaryItem
            label="IIV K<sub>D</sub> (%)"
            :item="IIV_KDB.value === '' ? { value: '-', ok: true } : IIV_KDB"
          />
          <h3
            class="title is-4"
            style="margin-top: 1.5em;"
          >
            System parameters
          </h3>
          <table
            class="table ist-table"
            style="margin-bottom: 2em; margin-top: 1.5em;"
          >
            <thead>
              <tr>
                <th />
                <th>D<sub>R_50</sub> (-)</th>
                <th>TST<sub>0</sub> (ng/mL)</th>
                <th>k<sub>IN</sub> (ng/(mL*day))</th>
                <th>k<sub>D_R</sub> (1/day)</th>
                <th>AGN (-)</th>
                <th>k<sub>D_T</sub> (1/day)</th>
                <th>γ (-)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Population value</td>
                <td>{{ E50.value }}</td>
                <td>{{ TST0.value }}</td>
                <td>{{ KIN.value }}</td>
                <td>{{ KREO.value }}</td>
                <td>{{ AGN.value }}</td>
                <td>{{ KDTT.value }}</td>
                <td>{{ GAM1.value }}</td>
              </tr>
              <tr>
                <td>IIV (%)</td>
                <td>{{ IIV_E50.value }}</td>
                <td>{{ IIV_TST0.value }}</td>
                <td>{{ IIV_KIN.value }}</td>
                <td>{{ IIV_KREO.value }}</td>
                <td>{{ IIV_AGN.value }}</td>
                <td>{{ IIV_KDTT.value }}</td>
                <td class="has-background-white-ter" />
              </tr>
            </tbody>
          </table>
        </div>
        <div id="summary-trial-design">
          <h3
            class="title is-4"
            style="margin-top: 1.5em;"
          >
            Trial Design
          </h3>
          <SummaryItem
            label="Number of subjects to simulate"
            :item="population"
          />
          <SummaryItem
            label="Testosterone concentration castration limit (ng/mL)"
            :item="castrationLimit"
          />
          <SummaryItem
            label="Study duration (day)"
            :item="studyDuration"
          />
          <div style="margin-top: 1.5em; margin-bottom: 2em;">
            <Table
              id="s4-table-schedules"
              :column-labels="dosingUploadLabels"
              :columns="dosingUploadHeaders"
              :rows="dosingTableData"
            />
          </div>
        </div>
        <p
          style="margin-top: 2em"
          data-cy="tokens"
        >
          The simulation will consume {{ tokenCounter }}
          <span>token</span>
          <span v-if="tokenCounter > 1">s</span>.
          <FieldHelp text="One token is counted per scheduled group." />
          <span
            class="tag is-medium space-left"
            :class="{ 'is-warning': tokenCounter > tokensAvailable }"
          >{{ tokensAvailable }} tokens available</span>
        </p>
        <p v-if="tokenCounter > tokensAvailable">
          Not enough available tokens.
        </p>
        <p
          v-if="!jobValidates"
          class="margin-top: 1.5em"
        >
          Please provide missing input.
        </p>
        <button
          id="s4-submit"
          class="button more-space"
          :class="{'is-warning': !allowedToSubmit, 'is-success': allowedToSubmit }"
          :disabled="isSubmitting || !allowedToSubmit"
          @click="submitSimulation"
        >
          <Loader :is-loading="isSubmitting" />
          Run
        </button>
        <StepButtons
          v-model="activeStep"
          is-last
        />
      </div>
    </div>
  </section>
</template>

<script>
import gql from 'graphql-tag';
import { saveAs } from 'file-saver';
import Field from 'ist-skeleton-vue/src/components/Simulation/Field.vue';
import FieldCheckbox from 'ist-skeleton-vue/src/components/Simulation/FieldCheckbox.vue';
import FieldHelp from 'ist-skeleton-vue/src/components/Simulation/FieldHelp.vue';
import FieldRadio from 'ist-skeleton-vue/src/components/Simulation/FieldRadio.vue';
import FieldSelect from 'ist-skeleton-vue/src/components/Simulation/FieldSelect.vue';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import StepButtons from 'ist-skeleton-vue/src/components/Simulation/StepButtons.vue';
import StepIndicator from 'ist-skeleton-vue/src/components/Simulation/StepIndicator.vue';
import SummaryItem from 'ist-skeleton-vue/src/components/Simulation/SummaryItem.vue';
import UploadCsv from 'ist-skeleton-vue/src/components/Simulation/UploadCsv.vue';
import Table from 'ist-skeleton-vue/src/components/Simulation/Table.vue';
import Concentrations from '../components/Concentrations.vue';

function numberOrNull(model) {
  if (model.value === '' || model.value == null) {
    return null;
  }
  return Number(model.value);
}

export default {
  components: {
    Concentrations,
    Field,
    FieldCheckbox,
    FieldHelp,
    FieldRadio,
    FieldSelect,
    Loader,
    StepButtons,
    StepIndicator,
    SummaryItem,
    Table,
    UploadCsv,
  },
  data() {
    return {
      activeStep: 0,
      highestVisitedStep: 0, // up to which step to run validation
      // tokens
      tokensAvailable: 0,
      tokenCounter: 1,
      // form status
      isSubmitting: false,
      jobValidates: false,
      stepWarnings: [false, false, false],
      // setup
      drugModeOptions: [
        { value: 'library', label: 'Select from library' },
        { value: 'custom', label: 'Define custom drug' },
      ],
      drugLibraryOptions: [
        { value: 'triptorelin', label: 'Triptorelin' },
        { value: 'leuprorelin', label: 'Leuprorelin' },
      ],
      customizeOptions: [
        { value: 'enabled', label: 'Customize settings' },
      ],
      // pharmacokinetic settings
      administrationTypeOptions: [
        { value: 'extravascular', label: 'Extravascular' },
        { value: 'intravenous', label: 'Intravenous' },
      ],
      processOptions: [
        { value: 'zero', label: 'Zero-order absorption process' },
        { value: 'first', label: 'First-order absorption process' },
      ],
      firstOrderCompartmentOptions: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
      ],
      depotOneLagTransitOptions: [
        { value: 'tlag', label: 'Lag time' },
        { value: 'transit', label: 'Transit compartment' },
      ],
      dispositionCompartmentOptions: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
      ],
      // pharmacodynamics
      advancedOptions: [
        { value: 'enabled', label: 'Show advanced settings' },
      ],
      // dosing
      dosingModeOptions: [
        { value: 'manual', label: 'Single dose' },
        { value: 'upload', label: 'Multiple doses' },
      ],
      // ###############
      // user data below
      // ###############
      //
      // setup
      title: { value: '', ok: false },
      description: '',
      drugMode: { value: 'library' },
      drug: { value: 'triptorelin' },
      formulationChoice: { value: 'A' },
      customizeChoice: { value: { enabled: false } },
      warningTotalF: false,
      // pharmacokinetic settings
      administrationTypeChoice: { value: 'extravascular' },
      processChoice: { value: { zero: true, first: true } },
      Dinf: { value: '2.49', ok: true },
      Finf: { value: '0.12', ok: true },
      tlaginf: { value: '0', ok: true },
      firstOrderCompartmentChoice: { value: '2' },
      KA1: { value: '0.015', ok: true },
      IIV_KA1: { value: '152', ok: true },
      F1: { value: '0.25', ok: true },
      depotOneLagTransitChoice: { value: 'tlag' },
      tlag1: { value: '0', ok: true },
      n1: { value: '', ok: true },
      mt1: { value: '', ok: true },
      KA2: { value: '0.099', ok: true },
      IIV_KA2: { value: '62', ok: true },
      F2: { value: '0.63', ok: true },
      depotTwoLagTransitChoice: { value: 'tlag' },
      tlag2: { value: '5.49', ok: true },
      n2: { value: '', ok: true },
      mt2: { value: '', ok: true },
      dispositionCompartmentChoice: { value: '3' },
      CL: { value: '274.3', ok: true },
      IIV_CL: { value: '10', ok: true },
      V: { value: '8.1', ok: true },
      IIV_V: { value: '37', ok: true },
      Q1: { value: '832.3', ok: true },
      IIV_Q1: { value: '', ok: true },
      V1: { value: '12', ok: true },
      IIV_V1: { value: '', ok: true },
      Q2: { value: '159.5', ok: true },
      IIV_Q2: { value: '', ok: true },
      V2: { value: '33.8', ok: true },
      IIV_V2: { value: '', ok: true },
      // pharmacodynamics
      // --- drug params
      KDB: { value: '0.931', ok: true },
      IIV_KDB: { value: '', ok: true },
      // --- system params
      advancedChoice: { value: { enabled: false } },
      E50: { value: '0.024', ok: true },
      IIV_E50: { value: '31.36', ok: true },
      KREO: { value: '0.185', ok: true },
      IIV_KREO: { value: '32.09', ok: true },
      KIN: { value: '0.041', ok: true },
      IIV_KIN: { value: '35.77', ok: true },
      KDTT: { value: '0.55', ok: true },
      IIV_KDTT: { value: '', ok: true },
      AGN: { value: '0.31', ok: true },
      IIV_AGN: { value: '', ok: true },
      GAM1: { value: '1', ok: true },
      TST0: { value: '3.98', ok: true },
      IIV_TST0: { value: '35.07', ok: true },
      // trial design
      population: { value: '', ok: false },
      castrationLimit: { value: '0.5', ok: true },
      studyDuration: { value: '', ok: false },
      dosingMode: { value: 'manual' },
      dosingStr: '',
      dosingData: [],
      dosingUpload: null, // otherwise { 'status': ..., 'filename': ..., 'data': ... }
      // for papers and CSV example downloads
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    workflow() {
      if (this.drugMode.value !== 'library'
        || this.formulationChoice.value === 'custom'
        || this.customizeChoice.value.enabled) {
        return 'advanced';
      }
      return 'simple';
    },
    steps() {
      if (this.workflow === 'simple') {
        return ['Setup', 'Trial Design', 'Run'];
      }
      return ['Setup', 'Pharmacokinetic Settings', 'Pharmacodynamic Settings', 'Trial Design', 'Run'];
    },
    activeStepName() {
      if (this.activeStep === 0) return 'setup';
      if (this.workflow === 'simple') {
        if (this.activeStep === 1) return 'trial_design';
      }
      if (this.workflow === 'advanced') {
        if (this.activeStep === 1) return 'pharmacokinetic_settings';
        if (this.activeStep === 2) return 'pharmacodynamic_settings';
        if (this.activeStep === 3) return 'trial_design';
      }
      return 'run';
    },
    allowedToSubmit() {
      return this.tokenCounter <= this.tokensAvailable && this.jobValidates;
    },
    formulationLibraryOptions() {
      if (this.drug.value === 'triptorelin') {
        return [
          { value: 'A', label: 'A. Microparticles, subcutaneous injection' },
          { value: 'B', label: 'B. Microtubules, subcutaneous injection' },
          { value: 'C', label: 'C. Microtubules, subcutaneous injection' },
          { value: 'D', label: 'D. Microparticles, subcutaneous injection' },
          { value: 'E', label: 'E. Microsphere, intramuscular injection' },
          { value: 'custom', label: 'custom' },
        ];
      }
      // if (this.drug.value === 'leuprorelin')
      return [
        { value: 'AA', label: 'Leuprorelin acetate for depot suspension' },
        { value: 'custom', label: 'custom' },
      ];
    },
    formulation() {
      const formulationName = this.formulationLibraryOptions.find((formulation) => formulation.value === this.formulationChoice.value).label;
      return { value: formulationName, ok: true };
    },
    dosingUploadHeaders() {
      if (this.dosingMode.value === 'upload' && this.administrationTypeChoice.value === 'intravenous') {
        return ['group', 'time', 'amt', 'tinf'];
      }
      return ['group', 'time', 'amt'];
    },
    dosingUploadLabels() {
      if (this.dosingMode.value === 'upload' && this.administrationTypeChoice.value === 'intravenous') {
        return ['Schedule ID', 'Day of injection', 'Amount (mg)', 'Infusion duration (days)'];
      }
      return ['Schedule ID', 'Day of injection', 'Amount (mg)'];
    },
    dosingFilename() {
      if (this.dosingUpload !== null) {
        return this.dosingUpload.filename;
      }
      return '';
    },
    dosingSummaryItem() {
      if (this.dosingMode === 0) {
        return {
          value: `${this.dosingStr} mg`,
          ok: this.dosingArray.every((v) => v.ok),
        };
      }
      return {
        value: this.dosingFilename,
        ok: this.dosingUploadStatus,
      };
    },
    absorptionFigure() {
      let filename = 'abso';
      if (this.processChoice.value.zero) {
        filename += '_0';
      }
      if (this.processChoice.value.first) {
        filename += `_1${this.depotOneLagTransitChoice.value}`;
        if (this.firstOrderCompartmentChoice.value === '2') {
          filename += `_2${this.depotTwoLagTransitChoice.value}`;
        }
      }
      return require(`../assets/absorption/${filename}.png`); // eslint-disable-line
    },
    dispositionFigure() {
      // eslint rules forbid using template strings for require, and also disallow global require
      // however there is no other way to achieve dynamic image loading...
      return require(`../assets/disposition/dispo_cmpt${this.dispositionCompartmentChoice.value}.png`); // eslint-disable-line
    },
    numberAbsorptionCompartments() {
      let compartments = 0;
      if (this.processChoice.value.zero) compartments += 1;
      if (this.processChoice.value.first) compartments += Number(this.firstOrderCompartmentChoice.value);
      return { value: compartments, ok: true };
    },
    dosingTableData() {
      if (this.dosingMode.value === 'upload') {
        return this.dosingUpload != null ? this.dosingUpload.data : [];
      }
      const data = [];
      for (let i = 0; i < this.dosingData.length; i += 1) {
        const dose = this.dosingData[i].value;
        data.push({ group: i + 1, time: 0, amt: dose });
      }
      return data;
    },
  },
  watch: {
    activeStep(newStep) {
      if (newStep > this.highestVisitedStep) this.highestVisitedStep = newStep;
      if (newStep === this.steps.length - 1) {
        this.countTokens();
        this.validateJob();
      }
    },
    drugMode() {
      this.customizeChoice.value.enabled = false;
      if (this.drugMode.value === 'custom') {
        this.formulationChoice.value = 'custom';
      } else {
        this.formulationChoice.value = this.formulationLibraryOptions[0].value;
      }
      this.fillDisposition();
      this.fillAbsorption();
      this.fillPharmacodynamicsDrug();
      this.fillPharmacodynamicsSystem();
    },
    drug() {
      this.customizeChoice.value.enabled = false;
      this.formulationChoice.value = this.formulationLibraryOptions[0].value;
      this.fillDisposition();
      this.fillAbsorption();
      this.fillPharmacodynamicsDrug();
      this.fillPharmacodynamicsSystem();
    },
    dosingMode() {
      this.dosingUpload = null;
      this.dosingStr = '';
      this.dosingData = [];
    },
    formulationChoice() {
      this.fillAbsorption();
    },
    administrationTypeChoice() {
      if (this.administrationTypeChoice.value === 'extravascular') {
        this.fillDisposition();
        this.fillAbsorption();
        this.fillPharmacodynamicsDrug();
        this.fillPharmacodynamicsSystem();
      } else { // intravenous
        this.resetDisposition();
        this.resetAbsorption();
        this.resetPharmacodynamicsDrug();
        this.resetPharmacodynamicsSystem();
      }
    },
    customizeChoice() {
      this.fillDisposition();
      this.fillAbsorption();
      this.fillPharmacodynamicsDrug();
      this.fillPharmacodynamicsSystem();
    },
    firstOrderCompartmentChoice() {
      if (this.firstOrderCompartmentChoice.value === '1') {
        this.KA2 = { value: '', ok: false };
        this.IIV_KA2 = { value: '', ok: true };
        this.F2 = { value: '', ok: false };
        this.tlag2 = { value: '', ok: false };
        this.n2 = { value: '', ok: false };
        this.mt2 = { value: '', ok: false };
      }
    },
    dispositionCompartmentChoice() {
      if (Number(this.dispositionCompartmentChoice.value) < 3) {
        this.Q2 = { value: '', ok: false };
        this.IIV_Q2 = { value: '', ok: false };
        this.V2 = { value: '', ok: false };
        this.IIV_V2 = { value: '', ok: false };
      }
      if (Number(this.dispositionCompartmentChoice.value) < 2) {
        this.Q1 = { value: '', ok: false };
        this.IIV_Q1 = { value: '', ok: false };
        this.V1 = { value: '', ok: false };
        this.IIV_V1 = { value: '', ok: false };
      }
    },
    advancedChoice: {
      handler() {
        this.fillPharmacodynamicsSystem();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchTokens();
  },
  methods: {
    async fetchTokens() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query getUserSubscription($product_name: ProductName!) {
            account: getUserSubscription(product_name: $product_name) {
              tokens_available
            }
          }`,
          variables: {
            product_name: this.$product.id,
          },
        });
        this.tokensAvailable = response.data.account.tokens_available;
      } catch (error) {
        console.error(error);
      }
    },
    countTokens() {
      this.tokenCounter = new Set(this.dosingTableData.map((dose) => dose.group)).size;
    },
    fillAbsorption() {
      if (this.drugMode.value === 'custom' || this.formulationChoice.value === 'custom') {
        this.resetAbsorption();
      } else {
        if (this.drug.value === 'triptorelin') {
          this.processChoice = { value: { zero: true, first: true } };
          this.firstOrderCompartmentChoice = { value: '2' };
          this.depotOneLagTransitChoice = { value: 'tlag' };
          this.depotTwoLagTransitChoice = { value: 'tlag' };
          if (this.formulationChoice.value === 'A') {
            this.Dinf = { value: '2.49', ok: true };
            this.Finf = { value: '0.12', ok: true };
            this.tlaginf = { value: '0', ok: true };
            this.KA1 = { value: '0.015', ok: true };
            this.IIV_KA1 = { value: '152', ok: true };
            this.F1 = { value: '0.25', ok: true };
            this.tlag1 = { value: '0', ok: true };
            this.n1 = { value: '', ok: true };
            this.mt1 = { value: '', ok: true };
            this.KA2 = { value: '0.099', ok: true };
            this.IIV_KA2 = { value: '62', ok: true };
            this.F2 = { value: '0.63', ok: true };
            this.tlag2 = { value: '5.49', ok: true };
            this.n2 = { value: '', ok: true };
            this.mt2 = { value: '', ok: true };
          } else if (this.formulationChoice.value === 'B') {
            this.Dinf = { value: '3.93', ok: true };
            this.Finf = { value: '0.29', ok: true };
            this.tlaginf = { value: '0', ok: true };
            this.KA1 = { value: '0.0257', ok: true };
            this.IIV_KA1 = { value: '19', ok: true };
            this.F1 = { value: '0.49', ok: true };
            this.tlag1 = { value: '11.8', ok: true };
            this.n1 = { value: '', ok: true };
            this.mt1 = { value: '', ok: true };
            this.KA2 = { value: '0.0035', ok: true };
            this.IIV_KA2 = { value: '', ok: true };
            this.F2 = { value: '0.22', ok: true };
            this.tlag2 = { value: '147', ok: true };
            this.n2 = { value: '', ok: true };
            this.mt2 = { value: '', ok: true };
          } else if (this.formulationChoice.value === 'C') {
            this.Dinf = { value: '0.24', ok: true };
            this.Finf = { value: '0.06', ok: true };
            this.tlaginf = { value: '0', ok: true };
            this.KA1 = { value: '0.047', ok: true };
            this.IIV_KA1 = { value: '', ok: true };
            this.F1 = { value: '0.18', ok: true };
            this.tlag1 = { value: '0.34', ok: true };
            this.n1 = { value: '', ok: true };
            this.mt1 = { value: '', ok: true };
            this.KA2 = { value: '0.0008', ok: true };
            this.IIV_KA2 = { value: '', ok: true };
            this.F2 = { value: '0.76', ok: true };
            this.tlag2 = { value: '69.79', ok: true };
            this.n2 = { value: '', ok: true };
            this.mt2 = { value: '', ok: true };
          } else if (this.formulationChoice.value === 'D') {
            this.Dinf = { value: '1.03', ok: true };
            this.Finf = { value: '0.03', ok: true };
            this.tlaginf = { value: '0', ok: true };
            this.KA1 = { value: '0.032', ok: true };
            this.IIV_KA1 = { value: '27', ok: true };
            this.F1 = { value: '0.34', ok: true };
            this.tlag1 = { value: '0', ok: true };
            this.n1 = { value: '', ok: true };
            this.mt1 = { value: '', ok: true };
            this.KA2 = { value: '0.15', ok: true };
            this.IIV_KA2 = { value: '', ok: true };
            this.F2 = { value: '0.63', ok: true };
            this.tlag2 = { value: '0', ok: true };
            this.n2 = { value: '', ok: true };
            this.mt2 = { value: '', ok: true };
          } else if (this.formulationChoice.value === 'E') {
            this.Dinf = { value: '0.77', ok: true };
            this.Finf = { value: '0.41', ok: true };
            this.tlaginf = { value: '0', ok: true };
            this.KA1 = { value: '0.019', ok: true };
            this.IIV_KA1 = { value: '0', ok: true };
            this.F1 = { value: '0.43', ok: true };
            this.tlag1 = { value: '0', ok: true };
            this.n1 = { value: '', ok: true };
            this.mt1 = { value: '', ok: true };
            this.KA2 = { value: '0.023', ok: true };
            this.IIV_KA2 = { value: '', ok: true };
            this.F2 = { value: '0.16', ok: true };
            this.tlag2 = { value: '111.9', ok: true };
            this.n2 = { value: '', ok: true };
            this.mt2 = { value: '', ok: true };
          } else {
            this.resetAbsorption();
          }
        }
        if (this.drug.value === 'leuprorelin') {
          this.processChoice = { value: { zero: true, first: true } };
          this.firstOrderCompartmentChoice = { value: '2' };
          this.depotOneLagTransitChoice = { value: 'transit' };
          this.depotTwoLagTransitChoice = { value: 'tlag' };
          if (this.formulationChoice.value === 'AA') {
            this.Dinf = { value: '0.0095', ok: true };
            this.Finf = { value: '0.0084', ok: true };
            this.tlaginf = { value: '0', ok: true };
            this.KA1 = { value: '0.017', ok: true };
            this.IIV_KA1 = { value: '23.3', ok: true };
            this.F1 = { value: '0.205', ok: true };
            this.tlag1 = { value: '', ok: true };
            this.n1 = { value: '21.6', ok: true };
            this.mt1 = { value: '9.507', ok: true };
            this.KA2 = { value: '0.357', ok: true };
            this.IIV_KA2 = { value: '23.3', ok: true };
            this.F2 = { value: '0.0076', ok: true };
            this.tlag2 = { value: '0', ok: true };
            this.n2 = { value: '', ok: true };
            this.mt2 = { value: '', ok: true };
          } else {
            this.resetAbsorption();
          }
        }
      }
    },
    fillDisposition() {
      if (this.drugMode.value === 'custom') {
        this.resetDisposition();
      } else {
        if (this.drug.value === 'triptorelin') {
          this.dispositionCompartmentChoice.value = '3';
          this.CL = { value: '274.3', ok: true };
          this.IIV_CL = { value: '10', ok: true };
          this.V = { value: '8.1', ok: true };
          this.IIV_V = { value: '37', ok: true };
          this.Q1 = { value: '832.3', ok: true };
          this.IIV_Q1 = { value: '', ok: true };
          this.V1 = { value: '12', ok: true };
          this.IIV_V1 = { value: '', ok: true };
          this.Q2 = { value: '159.5', ok: true };
          this.IIV_Q2 = { value: '', ok: true };
          this.V2 = { value: '33.8', ok: true };
          this.IIV_V2 = { value: '', ok: true };
        }
        if (this.drug.value === 'leuprorelin') {
          this.dispositionCompartmentChoice.value = '2';
          this.CL = { value: '198', ok: true };
          this.IIV_CL = { value: '31.5', ok: true };
          this.V = { value: '8.53', ok: true };
          this.IIV_V = { value: '88.7', ok: true };
          this.Q1 = { value: '261', ok: true };
          this.IIV_Q1 = { value: '', ok: true };
          this.V1 = { value: '11.5', ok: true };
          this.IIV_V1 = { value: '', ok: true };
          this.Q2 = { value: '', ok: true };
          this.IIV_Q2 = { value: '', ok: true };
          this.V2 = { value: '', ok: true };
          this.IIV_V2 = { value: '', ok: true };
        }
      }
    },
    fillPharmacodynamicsDrug() {
      if (this.drugMode.value === 'custom') {
        this.resetPharmacodynamicsDrug();
      } else {
        if (this.drug.value === 'triptorelin') {
          this.KDB = { value: '0.931', ok: true };
          this.IIV_KDB = { value: '', ok: true };
        }
        if (this.drug.value === 'leuprorelin') {
          this.KDB = { value: '0.302', ok: true };
          this.IIV_KDB = { value: '', ok: true };
        }
      }
    },
    fillPharmacodynamicsSystem() {
      if (this.drugMode.value === 'custom' || (this.drugMode.value === 'library' && this.drug.value === 'triptorelin')) {
        this.E50 = { value: '0.024', ok: true };
        this.IIV_E50 = { value: '31.36', ok: true };
        this.KREO = { value: '0.185', ok: true };
        this.IIV_KREO = { value: '32.09', ok: true };
        this.KIN = { value: '0.041', ok: true };
        this.IIV_KIN = { value: '35.77', ok: true };
        this.KDTT = { value: '0.55', ok: true };
        this.IIV_KDTT = { value: '', ok: true };
        this.AGN = { value: '0.31', ok: true };
        this.IIV_AGN = { value: '', ok: true };
        this.GAM1 = { value: '1', ok: true };
        this.TST0 = { value: '3.98', ok: true };
        this.IIV_TST0 = { value: '35.07', ok: true };
      }
      if (this.drugMode.value === 'library' && this.drug.value === 'leuprorelin') {
        this.E50 = { value: '0.013', ok: true };
        this.IIV_E50 = { value: '', ok: true };
        this.KREO = { value: '0.234', ok: true };
        this.IIV_KREO = { value: '', ok: true };
        this.KIN = { value: '0.018', ok: true };
        this.IIV_KIN = { value: '', ok: true };
        this.KDTT = { value: '0.233', ok: true };
        this.IIV_KDTT = { value: '', ok: true };
        this.AGN = { value: '0.222', ok: true };
        this.IIV_AGN = { value: '', ok: true };
        this.GAM1 = { value: '3.838', ok: true };
        this.TST0 = { value: '3.896', ok: true };
        this.IIV_TST0 = { value: '49.7', ok: true };
      }
    },
    resetAbsorption() {
      this.processChoice = { value: { zero: false, first: false } };
      this.firstOrderCompartmentChoice = { value: '1' };
      this.depotOneLagTransitChoice = { value: 'tlag' };
      this.depotTwoLagTransitChoice = { value: 'tlag' };
      this.Dinf = { value: '', ok: false };
      this.Finf = { value: '', ok: false };
      this.tlaginf = { value: 0, ok: true };
      this.KA1 = { value: '', ok: false };
      this.IIV_KA1 = { value: '', ok: true };
      this.F1 = { value: '', ok: false };
      this.tlag1 = { value: '', ok: false };
      this.n1 = { value: '', ok: false };
      this.mt1 = { value: '', ok: false };
      this.KA2 = { value: '', ok: false };
      this.IIV_KA2 = { value: '', ok: true };
      this.F2 = { value: '', ok: false };
      this.tlag2 = { value: '', ok: false };
      this.n2 = { value: '', ok: false };
      this.mt2 = { value: '', ok: false };
    },
    resetDisposition() {
      this.dispositionCompartmentChoice.value = '1';
      this.CL = { value: '', ok: false };
      this.IIV_CL = { value: '', ok: true };
      this.V = { value: '', ok: false };
      this.IIV_V = { value: '', ok: true };
      this.Q1 = { value: '', ok: false };
      this.IIV_Q1 = { value: '', ok: true };
      this.V1 = { value: '', ok: false };
      this.IIV_V1 = { value: '', ok: true };
      this.Q2 = { value: '', ok: false };
      this.IIV_Q2 = { value: '', ok: true };
      this.V2 = { value: '', ok: false };
      this.IIV_V2 = { value: '', ok: true };
    },
    resetPharmacodynamicsDrug() {
      this.KDB = { value: '', ok: false };
      this.IIV_KDB = { value: '', ok: true };
    },
    resetPharmacodynamicsSystem() {
      this.E50 = { value: '0.024', ok: true };
      this.IIV_E50 = { value: '31.36', ok: true };
      this.KREO = { value: '0.185', ok: true };
      this.IIV_KREO = { value: '32.09', ok: true };
      this.KIN = { value: '0.041', ok: true };
      this.IIV_KIN = { value: '35.77', ok: true };
      this.KDTT = { value: '0.55', ok: true };
      this.IIV_KDTT = { value: '', ok: true };
      this.AGN = { value: '0.31', ok: true };
      this.IIV_AGN = { value: '', ok: true };
      this.GAM1 = { value: '1', ok: true };
      this.TST0 = { value: '3.98', ok: true };
      this.IIV_TST0 = { value: '35.07', ok: true };
    },
    validateJob() {
      // setup
      const setupValidates = this.title.ok;
      // pharmacokinetics
      let kineticsValidates = true;
      // -- absorption
      let totalF = 0; // to validate Finf + F1 + F2 <= 1
      if (this.processChoice.value.zero) {
        kineticsValidates = kineticsValidates && this.Dinf.ok && this.Finf.ok && this.tlaginf.ok;
        totalF += Number(this.Finf.value);
      }
      if (this.processChoice.value.first) {
        kineticsValidates = kineticsValidates && this.KA1.ok && this.IIV_KA1.ok && this.F1.ok;
        totalF += Number(this.F1.value);
        if (this.depotOneLagTransitChoice.value === 'tlag') {
          kineticsValidates = kineticsValidates && this.tlag1.ok;
        } else { // transit
          kineticsValidates = kineticsValidates && this.n1.ok && this.mt1.ok;
        }
        if (this.firstOrderCompartmentChoice.value === '2') {
          kineticsValidates = kineticsValidates && this.KA2.ok && this.IIV_KA2.ok && this.F2.ok;
          totalF += Number(this.F2.value);
          if (this.depotTwoLagTransitChoice.value === 'tlag') {
            kineticsValidates = kineticsValidates && this.tlag2.ok;
          } else { // transit
            kineticsValidates = kineticsValidates && this.n2.ok && this.mt2.ok;
          }
        }
      }
      if (totalF > 1.0) {
        kineticsValidates = false;
        this.warningTotalF = true;
      } else {
        this.warningTotalF = false;
      }
      // -- disposition
      kineticsValidates = kineticsValidates && this.CL.ok && this.V.ok && this.IIV_CL.ok && this.IIV_V.ok;
      if (this.dispositionCompartmentChoice.value > 1) {
        kineticsValidates = kineticsValidates && this.Q1.ok && this.V1.ok && this.IIV_Q1.ok && this.IIV_V1.ok;
      }
      if (this.dispositionCompartmentChoice.value > 2) {
        kineticsValidates = kineticsValidates && this.Q2.ok && this.V2.ok && this.IIV_Q2.ok && this.IIV_V2.ok;
      }
      // pharmacodynamics
      const dynamicsValidates = this.KDB.ok && this.IIV_KDB.ok
        && this.E50.ok && this.IIV_E50.ok
        && this.TST0.ok && this.IIV_TST0.ok
        && this.KIN.ok && this.IIV_KIN.ok
        && this.KREO.ok && this.IIV_KREO.ok
        && this.AGN.ok && this.IIV_AGN.ok
        && this.KDTT.ok && this.IIV_KDTT.ok
        && this.GAM1.ok;
      // trial design
      let trialDesignValidates = this.population.ok && this.studyDuration.ok && this.castrationLimit.ok;
      if (this.dosingMode.value === 'manual') {
        if (this.dosingData.length === 0 || this.dosingData.some((obj) => !obj.ok)) trialDesignValidates = false;
      } else if (this.dosingUpload == null || this.dosingUpload.status !== 'ok') {
        trialDesignValidates = false;
      }
      // summary
      if (this.workflow === 'simple') {
        this.stepWarnings = [!setupValidates, !trialDesignValidates, false];
        this.jobValidates = setupValidates && trialDesignValidates;
      } else {
        this.stepWarnings = [!setupValidates, !kineticsValidates, !dynamicsValidates, !trialDesignValidates, false];
        this.jobValidates = setupValidates && kineticsValidates && dynamicsValidates && trialDesignValidates;
      }
    },
    async submitSimulation() {
      this.isSubmitting = true;
      try {
        const inputData = {
          drug: this.drugMode.value === 'library' ? this.drug.value : 'custom',
          formulation: this.formulationChoice.value,
          // pharmacokinetic
          extravascular: this.administrationTypeChoice.value === 'extravascular',
          intravenous: this.administrationTypeChoice.value === 'intravenous',
          // pharmacokinetic -- disposition
          CL: numberOrNull(this.CL),
          IIV_CL: numberOrNull(this.IIV_CL),
          V: numberOrNull(this.V),
          IIV_V: numberOrNull(this.IIV_V),
          Q1: numberOrNull(this.Q1),
          IIV_Q1: numberOrNull(this.IIV_Q1),
          V1: numberOrNull(this.V1),
          IIV_V1: numberOrNull(this.IIV_V1),
          Q2: numberOrNull(this.Q2),
          IIV_Q2: numberOrNull(this.IIV_Q2),
          V2: numberOrNull(this.V2),
          IIV_V2: numberOrNull(this.IIV_V2),
          // pharmacokinetic -- absorption
          transit_cmpt1: this.depotOneLagTransitChoice.value === 'transit',
          transit_cmpt2: this.depotTwoLagTransitChoice.value === 'transit',
          Dinf: numberOrNull(this.Dinf),
          Finf: numberOrNull(this.Finf),
          tlaginf: numberOrNull(this.tlaginf),
          KA1: numberOrNull(this.KA1),
          IIV_KA1: numberOrNull(this.IIV_KA1),
          F1: numberOrNull(this.F1),
          tlag1: numberOrNull(this.tlag1),
          n1: numberOrNull(this.n1),
          mt1: numberOrNull(this.mt1),
          KA2: numberOrNull(this.KA2),
          IIV_KA2: numberOrNull(this.IIV_KA2),
          F2: numberOrNull(this.F2),
          tlag2: numberOrNull(this.tlag2),
          n2: numberOrNull(this.n2),
          mt2: numberOrNull(this.mt2),
          // pharmacodynamic
          KDB: numberOrNull(this.KDB),
          IIV_KDB: numberOrNull(this.IIV_KDB),
          E50: numberOrNull(this.E50),
          IIV_EC50: numberOrNull(this.IIV_E50), // yes, EC50
          KREO: numberOrNull(this.KREO),
          IIV_KREO: numberOrNull(this.IIV_KREO),
          KIN: numberOrNull(this.KIN),
          IIV_KIN: numberOrNull(this.IIV_KIN),
          KDTT: numberOrNull(this.KDTT),
          IIV_KDTT: numberOrNull(this.IIV_KDTT),
          AGN: numberOrNull(this.AGN),
          IIV_AGN: numberOrNull(this.IIV_AGN),
          GAM1: numberOrNull(this.GAM1),
          TST0: numberOrNull(this.TST0),
          IIV_TST0: numberOrNull(this.IIV_TST0),
          // trial design
          nsubj: numberOrNull(this.population),
          threscastr: numberOrNull(this.castrationLimit),
          Tmax: numberOrNull(this.studyDuration),
          scheduling: this.dosingTableData,
        };

        if (window.Cypress) {
          // During E2E testing, input data is saved to global window object for retrieval.
          window.testSimulationInputs = inputData;
        }

        await this.$apollo.mutate({
          mutation: gql`
          mutation addGnrhJobAndTasks($product_name: ProductName!, $tasks: [GnrhTaskIn]!, $job_name: String!) {
            job: addGnrhJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
              product_name
            }
          }`,
          variables: {
            product_name: this.$product.id,
            tasks: [inputData],
            job_name: this.title.value,
          },
        });
        this.$router.push('/home');
      } catch (error) {
        console.error(error);
      }
    },
    downloadInputs(jsonData) {
      const fileName = 'input.json';
      const fileToSave = new Blob([JSON.stringify(jsonData, null, 2)], {
        type: 'application/json',
        name: fileName,
      });
      saveAs(fileToSave, fileName);
    },
    handleConcentrationsUpdate(arr) {
      this.dosingData = arr;
    },
    capitalise(modelValue) {
      return {
        value: modelValue.value.charAt(0).toUpperCase() + modelValue.value.slice(1),
        ok: true,
      };
    },
  },
};
</script>

<style>
.more-space {
  margin-top: 1em;
}
.space-left {
  margin-left: 0.5em;
}
.has-bottom-field {
  display: flex;
  align-items: flex-end;
}
</style>
