<template>
  <div class="documentation">
    <h1 class="title">
      Documentation
    </h1>
    <p>
      PCa GnRH Agonists Simulator is based on a mechanistic pharmacokinetic/pharmacodynamic computational model which describes the process of testosterone suppression by a GnRH agonist treatment in prostate cancer patients
      <span>(</span>
      <span>Romero et al. 2012</span>
      <span>)</span>.
      The computational model has been calibrated on data obtained from triptorelin (Romero et al. 2012) and leuprorelin
      <span>(</span>
      <span>Lim et al. 2015</span>
      <span>)</span> clinical trials.
      It can be easily applied to any GnRH agonists.
    </p>
    <p style="margin-top: 0.75em;">
      PCa GnRH Agonists Simulator enables simulations of clinical trials on a virtual population of prostate cancer patients being treated with a GnRH agonist.
      The tool can be used to explore different trial design scenarios in terms of GnRH agonists pharmacokinetic and pharmacodynamic properties, single and multiple dosing, administration route, formulation type, virtual population size as well as testosterone castration limit.
    </p>
    <div class="with-space">
      <h2 class="title is-4">
        Publications
      </h2>
      <ul>
        <li class="with-space">
          <span>
            Pharmacokinetic/Pharmacodynamic Model of the Testosterone Effects of Triptorelin Administered in Sustained Release Formulations in Patients with Prostate Cancer
          </span>
          <br>Romero, E. et al. J Pharmacol Exp Ther.
          <b>2012</b>
          <br>DOI: 10.1124/jpet.112.195560
        </li>
        <li class="with-space">
          <span>
            A Semi-Mechanistic Integrated Pharmacokinetic/Pharmacodynamic Model of the Testosterone Effects of the Gonadotropin-Releasing Hormone Agonist Leuprolide in Prostate Cancer Patients
          </span>
          <br>Lim, C.N. et al. Clin Pharmacokinet.
          <b>2015</b>
          <br>DOI: 10.1007/s40262-015-0251-9
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
div.documentation {
  padding-right: 2em;
  line-height: 1.7;
}
</style>
