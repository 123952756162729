<template>
  <div class="section">
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-4 is-vertical is-parent">
              <div class="tile is-child box">
                <NewSimulation />
              </div>
              <div class="tile is-child box">
                <Support />
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <LatestActivity />
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <HowToUse>
                  <p>
                    To simulate a clinical trial for a GnRH agonist drug in prostate cancer patients, PCa GnRH Agonists Simulator requires information about the pharmacokinetic and pharmacodynamic properties of the compound and the trial design specifications.
                    The pharmacokinetic and pharmacodynamic properties of a number of known drugs are available in the GnRH agonists library.
                    Further, a custom option enables the user to input pharmacokinetic and pharmacodynamic properties for a compound of interest.
                  </p>
                  <p style="margin-top: 0.75em;">
                    Detailed information on how to use PCa GnRH Agonists Simulator is provided in the user manual below.
                  </p>
                  <ul style="margin-top: 0.75em;">
                    <li>
                      <a
                        :href="`${publicPath}pca_gnrh_agonists.pdf`"
                        target="_blank"
                        rel="noopener noreferrer"
                      >User Manual
                      </a>
                    </li>
                  </ul>
                </HowToUse>
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <Documentation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HowToUse from 'ist-skeleton-vue/src/components/Home/HowToUse.vue';
import LatestActivity from 'ist-skeleton-vue/src/components/Home/LatestActivity.vue';
import NewSimulation from 'ist-skeleton-vue/src/components/Home/NewSimulation.vue';
import Support from 'ist-skeleton-vue/src/components/Home/Support.vue';
import Documentation from '../components/Documentation.vue';

export default {
  components: {
    Documentation,
    HowToUse,
    LatestActivity,
    NewSimulation,
    Support,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: mapState({
    userName: (state) => {
      if (state.iam.user) {
        return state.iam.user.name;
      }
      return 'user';
    },
  }),
};
</script>
