<template>
  <div>
    <div class="field">
      <label class="label">
        <span>Enter one or more doses to test on the virtual population (mg)</span>
        <font-awesome-icon
          icon="info-circle"
          title="Separate the doses by semicolons. For example 1;2;3 or 5.50;23.45;10000. Doses must be positive. Each inserted dose will be simulated on the virtual population assuming it is administered at the beginning of the study (day 0)."
          class="help-icon"
        />
      </label>
      <p class="control">
        <input
          id="s2-concentrations"
          class="input"
          type="text"
          :value="modelValue"
          :class="{ 'is-warning': hasWarning }"
          @input="onInput"
        >
      </p>
      <p class="help">
        <span
          v-if="hasWarning"
          class="has-text-warning"
        >
          <font-awesome-icon
            icon="exclamation-triangle"
            class="warning-icon"
          />
          &nbsp;{{ errorMessage }}
        </span>
      </p>
    </div>
    <p>Doses check:</p>
    <ul
      v-if="concentrations.length>0"
      class="with-space"
    >
      <li
        v-for="(v, index) in concentrations"
        :key="index"
        :class="{ 'has-text-warning': !v.ok }"
      >
        {{ v.value }}
        <span
          v-if="!v.ok"
          class="has-text-warning"
        >
          <font-awesome-icon icon="exclamation-triangle" />
        </span>
      </li>
    </ul>
    <p v-if="modelValue.length === 0">
      <span class="is-italic">None</span>
    </p>
  </div>
</template>

<script>

function allGood(arr) {
  return arr.length > 0 && arr.every((obj) => obj.ok);
}

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    concentrations: {
      type: Array,
      default() {
        return [];
      },
    },
    validate: Boolean,
  },
  emits: ['update:modelValue', 'concentrationsUpdate'],
  computed: {
    hasWarning() {
      return this.validate && !allGood(this.concentrations);
    },
    errorMessage() {
      if (this.modelValue === '') return 'Missing input.';
      return 'Invalid input.';
    },
  },
  methods: {
    allGood,
    onInput(event) {
      const { value } = event.target;
      const concentrations = [];
      value.split(';').forEach((v) => {
        if (v === '') return;
        const ok = v !== '' && !(Number.isNaN(Number(v)) || v <= 0);
        concentrations.push({
          value: v, ok,
        });
      });
      this.$emit('update:modelValue', value);
      this.$emit('concentrationsUpdate', concentrations);
    },
  },
};
</script>

<style scoped>
.help-icon {
  cursor: help;
  margin-left: 0.25em;
}
</style>
