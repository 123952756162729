import image from '../assets/product.jpg';

const product = {
  id: 'gnrh',
  name: 'PCa GnRH Agonists Simulator',
  title: 'PCa GnRH Agonists Simulator',
  subtitle: 'Simulation of the GnRH agonists effects on<br />testosterone in prostate cancer patients',
  description: 'A tool that simulates the effect of GnRH agonists on testosterone and allows to perform in silico clinical trials in a virtual population of prostate cancer patients.',
  collaboration: 'PCa GnRH Agonists Simulator is the result of the collaboration between the University of Navarra and InSilicoTrials Technologies.',
  image,
  features: [
    {
      icon: 'clipboard-check',
      title: 'Gold-standard',
      description: 'The reference model to predict testosterone suppression following GnRH agonists therapy',
    },
    {
      icon: 'download',
      title: 'Display and Export',
      description: 'Results can easily be visualized and downloaded in CSV format and a simulation report is available in PDF',
    },
    {
      icon: 'thumbs-up',
      title: 'Easy to Use',
      description: 'Well-designed wizard that step-by-step guides through the setup and run of the simulation',
    },
  ],
};

export default product;
