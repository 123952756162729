<template>
  <div class="section">
    <div class="container ist-container">
      <template v-if="task == null">
        <h1 class="title">
          <Loader :is-loading="true" />
          Results
        </h1>
      </template>
      <template v-else>
        <div class="buttons is-pulled-right">
          <EditJobName
            :name="job.name"
            @jobNameEdit="handleJobNameEdit"
          />
          <ReportModal
            v-if="job.status == 'completed' && task.status === 'success'"
            :is-loading="isPdfLoading"
            @generateReport="downloadPdf"
          />
        </div>
        <h1
          class="title no-space-bottom"
          data-cy="job-title"
        >
          {{ job.name }}
        </h1>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p>started: {{ $filters.formatDate(task.started_at) }}</p>
            </div>
            <div class="level-item">
              <p>completed: {{ $filters.formatDate(task.finished_at) }}</p>
            </div>
            <div class="level-item">
              <span
                class="tag is-medium"
                :class="{ 'is-warning': task.status !== 'success' }"
                data-cy="task-status"
              >{{ task.status }}</span>
            </div>
          </div>
        </div>
        <InputsSummary>
          <div style="margin-bottom: 2em;">
            <SummaryItem
              label="Drug"
              :value="capitalise(input.drug)"
            />
            <SummaryItem
              label="SR formulation"
              :value="formulation"
            />
            <h3
              class="title is-6"
              style="margin-top: 1.5em; margin-bottom: 0.5em;"
            >
              Pharmacokinetic Settings - Absorption
            </h3>
            <SummaryItem
              label="Administration type"
              :value="administration"
            />
            <table
              class="table ist-table"
              style="margin-top: 1.5em;"
              data-cy="inputs-absorption-table"
            >
              <colgroup>
                <col>
                <col style="border-left: 1px solid #dbdbdb;">
                <col span="2">
                <col style="border-left: 1px solid #dbdbdb;">
                <col :span="input.transit_cmpt1 ? 3 : 2">
                <col style="border-left: 1px solid #dbdbdb;">
                <col :span="input.transit_cmpt2 ? 3 : 2">
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th colspan="3">
                    Zero-order process
                  </th>
                  <th :colspan="input.transit_cmpt1 ? 4 : 3">
                    Depot 1
                  </th>
                  <th :colspan="input.transit_cmpt2 ? 4 : 3">
                    Depot 2
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td />
                  <td>D<sub>inf</sub> (day)</td>
                  <td>F<sub>inf</sub></td>
                  <td>t<sub>laginf</sub> (day)</td>
                  <td>K<sub>A1</sub> (1/day)</td>
                  <td v-if="!input.transit_cmpt1">
                    t<sub>lag1</sub> (day)
                  </td>
                  <template v-else>
                    <td>n<sub>1</sub></td>
                    <td>mt<sub>1</sub> (day)</td>
                  </template>
                  <td>F<sub>1</sub></td>
                  <td>K<sub>A2</sub> (1/day)</td>
                  <td v-if="!input.transit_cmpt2">
                    t<sub>lag2</sub> (day)
                  </td>
                  <template v-else>
                    <td>n<sub>2</sub></td>
                    <td>mt<sub>2</sub> (day)</td>
                  </template>
                  <td>F<sub>2</sub></td>
                </tr>
                <tr>
                  <td>Population</td>
                  <td :class="{'has-background-white-ter': input.Dinf == null}">
                    {{ input.Dinf }}
                  </td>
                  <td :class="{'has-background-white-ter': input.Finf == null}">
                    {{ input.Finf }}
                  </td>
                  <td :class="{'has-background-white-ter': input.tlaginf == null}">
                    {{ input.tlaginf }}
                  </td>
                  <td :class="{'has-background-white-ter': input.KA1 == null}">
                    {{ input.KA1 }}
                  </td>
                  <td
                    v-if="!input.transit_cmpt1"
                    :class="{'has-background-white-ter': input.tlag1 == null}"
                  >
                    {{ input.tlag1 }}
                  </td>
                  <template v-else>
                    <td :class="{'has-background-white-ter': input.n1 == null}">
                      {{ input.n1 }}
                    </td>
                    <td :class="{'has-background-white-ter': input.mt1 == null}">
                      {{ input.mt1 }}
                    </td>
                  </template>
                  <td :class="{'has-background-white-ter': input.F1 == null}">
                    {{ input.F1 }}
                  </td>
                  <td :class="{'has-background-white-ter': input.KA2 == null}">
                    {{ input.KA2 }}
                  </td>
                  <td
                    v-if="!input.transit_cmpt2"
                    :class="{'has-background-white-ter': input.tlag2 == null}"
                  >
                    {{ input.tlag2 }}
                  </td>
                  <template v-else>
                    <td :class="{'has-background-white-ter': input.n2 == null}">
                      {{ input.n2 }}
                    </td>
                    <td :class="{'has-background-white-ter': input.mt2 == null}">
                      {{ input.mt2 }}
                    </td>
                  </template>
                  <td :class="{'has-background-white-ter': input.F2 == null}">
                    {{ input.F2 }}
                  </td>
                </tr>
                <tr>
                  <td>IIV (%)</td>
                  <td
                    colspan="3"
                    class="has-background-white-ter"
                  />
                  <td :class="{'has-background-white-ter': input.IIV_KA1 == null}">
                    {{ input.IIV_KA1 }}
                  </td>
                  <td
                    :colspan="input.transit_cmpt1 ? 3 : 2"
                    class="has-background-white-ter"
                  />
                  <td :class="{'has-background-white-ter': input.IIV_KA2 == null}">
                    {{ input.IIV_KA2 }}
                  </td>
                  <td
                    :colspan="input.transit_cmpt2 ? 3 : 2"
                    class="has-background-white-ter"
                  />
                </tr>
              </tbody>
            </table>
            <h3
              class="title is-6"
              style="margin-top: 2em; margin-bottom: 0.5em;"
            >
              Pharmacokinetic Settings - Disposition
            </h3>
            <table
              class="table ist-table"
              style="margin-top: 1.5em;"
              data-cy="inputs-disposition-table"
            >
              <colgroup>
                <col>
                <col style="border-left: 1px solid #dbdbdb;">
                <col>
                <col style="border-left: 1px solid #dbdbdb;">
                <col>
                <col style="border-left: 1px solid #dbdbdb;">
                <col>
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th colspan="2">
                    Central compartment
                  </th>
                  <th colspan="2">
                    Peripheral compartment 1
                  </th>
                  <th colspan="2">
                    Peripheral compartment 2
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td />
                  <td>CL (L/day)</td>
                  <td>V (L)</td>
                  <td>Q<sub>1</sub> (L/day)</td>
                  <td>V<sub>1</sub> (L)</td>
                  <td>Q<sub>2</sub> (L/day)</td>
                  <td>V<sub>2</sub> (L)</td>
                </tr>
                <tr>
                  <td>Population</td>
                  <td :class="{'has-background-white-ter': input.CL == null}">
                    {{ input.CL }}
                  </td>
                  <td :class="{'has-background-white-ter': input.V == null}">
                    {{ input.V }}
                  </td>
                  <td :class="{'has-background-white-ter': input.Q1 == null}">
                    {{ input.Q1 }}
                  </td>
                  <td :class="{'has-background-white-ter': input.V1 == null}">
                    {{ input.V1 }}
                  </td>
                  <td :class="{'has-background-white-ter': input.Q2 == null}">
                    {{ input.Q2 }}
                  </td>
                  <td :class="{'has-background-white-ter': input.V2 == null}">
                    {{ input.V2 }}
                  </td>
                </tr>
                <tr>
                  <td>IIV (%)</td>
                  <td :class="{'has-background-white-ter': input.IIV_CL == null}">
                    {{ input.IIV_CL }}
                  </td>
                  <td :class="{'has-background-white-ter': input.IIV_V == null}">
                    {{ input.IIV_V }}
                  </td>
                  <td :class="{'has-background-white-ter': input.IIV_Q1 == null}">
                    {{ input.IIV_Q1 }}
                  </td>
                  <td :class="{'has-background-white-ter': input.IIV_V1 == null}">
                    {{ input.IIV_V1 }}
                  </td>
                  <td :class="{'has-background-white-ter': input.IIV_Q2 == null}">
                    {{ input.IIV_Q2 }}
                  </td>
                  <td :class="{'has-background-white-ter': input.IIV_V2 == null}">
                    {{ input.IIV_V2 }}
                  </td>
                </tr>
              </tbody>
            </table>
            <h3
              class="title is-6"
              style="margin-top: 2em; margin-bottom: 0.5em;"
            >
              Pharmacodynamic Settings
            </h3>
            <SummaryItem
              label="GnRH agonist receptor equilibrium dissociation constant K<sub>D</sub>"
              :value="String(input.KDB)"
              units="ng/mL"
            />
            <SummaryItem
              label="IIV K<sub>D</sub>"
              :value="input.IIV_KDB == null ? '-' : String(input.IIV_KDB)"
              units="%"
            />
            <table
              class="table ist-table"
              style="margin-top: 1.5em; margin-bottom: 2em;"
              data-cy="inputs-system-table"
            >
              <thead>
                <tr>
                  <th />
                  <th>D<sub>R_50</sub> (-)</th>
                  <th>TST<sub>0</sub> (ng/mL)</th>
                  <th>k<sub>IN</sub> (ng/(mL*day))</th>
                  <th>k<sub>D_R</sub> (1/day)</th>
                  <th>AGN (-)</th>
                  <th>k<sub>D_T</sub> (1/day)</th>
                  <th>γ (-)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Population</td>
                  <td>{{ input.E50 }}</td>
                  <td>{{ input.TST0 }}</td>
                  <td>{{ input.KIN }}</td>
                  <td>{{ input.KREO }}</td>
                  <td>{{ input.AGN }}</td>
                  <td>{{ input.KDTT }}</td>
                  <td>{{ input.GAM1 }}</td>
                </tr>
                <tr>
                  <td>IIV (%)</td>
                  <td :class="{'has-background-white-ter': input.IIV_EC50 == null}">
                    {{ input.IIV_EC50 }}
                  </td>
                  <td :class="{'has-background-white-ter': input.IIV_TST0 == null}">
                    {{ input.IIV_TST0 }}
                  </td>
                  <td :class="{'has-background-white-ter': input.IIV_KIN == null}">
                    {{ input.IIV_KIN }}
                  </td>
                  <td :class="{'has-background-white-ter': input.IIV_KREO == null}">
                    {{ input.IIV_KREO }}
                  </td>
                  <td :class="{'has-background-white-ter': input.IIV_AGN == null}">
                    {{ input.IIV_AGN }}
                  </td>
                  <td :class="{'has-background-white-ter': input.IIV_KDTT == null}">
                    {{ input.IIV_KDTT }}
                  </td>
                  <td class="has-background-white-ter" />
                </tr>
              </tbody>
            </table>
            <h3
              class="title is-6"
              style="margin-top: 2em; margin-bottom: 0.5em;"
            >
              Trial Design
            </h3>
            <SummaryItem
              label="Number of subjects"
              :value="String(input.nsubj)"
            />
            <div style="margin-bottom: 2em;">
              <Table
                id="inputs-schedules-table"
                :column-labels="dosingUploadLabels"
                :columns="dosingUploadHeaders"
                :rows="schedule"
              />
            </div>
            <SummaryItem
              label="End of study"
              :value="'day ' + input.Tmax"
            />
            <SummaryItem
              label="Testosterone concentration castration limit"
              :value="String(input.threscastr)"
              units="ng/mL"
            />
          </div>
          <hr>
        </InputsSummary>
        <template v-if="job.status == 'completed' && task.status === 'success'">
          <h2 class="title is-4">
            Results
          </h2>
          <p>
            Percentage of virtual patients reaching testosterone plasma concentration levels &lt;
            <span class="has-text-weight-bold">
              {{ input.threscastr }} ng/mL
            </span>
            every four weeks until the end of study
          </p>
          <div class="table-container">
            <table
              v-if="output != null && output.length > 0"
              class="table ist-table is-striped"
              style="margin-top: 1.5em;"
              data-cy="results-table"
            >
              <thead>
                <tr>
                  <th>Schedule ID</th>
                  <th
                    v-for="(col, key) in output[0].schedule"
                    :key="key"
                  >
                    Day {{ col.Day }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(group, key) in output"
                  :key="key"
                >
                  <td>{{ group.id }}</td>
                  <td
                    v-for="(col, key2) in group.schedule"
                    :key="key2"
                  >
                    {{ col.Percentage }}&nbsp;%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tabs is-toggle is-centered tabs-with-space">
            <ul>
              <li
                v-for="(group, key) in output"
                :key="key"
                :class="{ 'is-active': activeSchedule === group.id }"
              >
                <a @click="activeSchedule = group.id">
                  Schedule {{ group.id }}
                </a>
              </li>
            </ul>
          </div>
          <h3 class="title is-5">
            Drug Plasma Concentration
          </h3>
          <p>
            Predicted drug plasma concentration population distribution: median value (continuous line) and 90&nbsp;% prediction interval (colored area).
          </p>
          <figure class="image figure-with-space">
            <img
              v-if="activeSchedule != null"
              :src="resultsURL(`drug_conc${activeSchedule}.png`)"
              data-cy="drug-plasma-concentration-plot"
            >
          </figure>
          <h3 class="title is-5">
            Testosterone plasma concentration
          </h3>
          <p>
            Predicted testosterone plasma concentrations population distribution:
            median value (continuous line) and 90&nbsp;% prediction interval (colored area).
            The dotted line represents the testosterone concentration castration limit.
          </p>
          <figure class="image figure-with-space">
            <img
              v-if="activeSchedule != null"
              :src="resultsURL(`test_conc${activeSchedule}.png`)"
            >
          </figure>
          <h3 class="title is-5">
            Percentage of patients achieving chemical castration
          </h3>
          <figure class="image figure-with-space">
            <img
              v-if="activeSchedule != null"
              :src="resultsURL(`castr_subj${activeSchedule}.png`)"
            >
          </figure>
          <p class="buttons">
            <a
              class="button is-primary"
              style="margin-right: 0.25em;"
              :href="resultsURL('results.csv')"
              target="_blank"
              download
              data-cy="results-download"
            >
              <font-awesome-icon
                class="icon-with-margin"
                icon="download"
              />
              Results
            </a>
          </p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Papa from 'papaparse';
import EditJobName from 'ist-skeleton-vue/src/components/Results/EditJobName.vue';
import InputsSummary from 'ist-skeleton-vue/src/components/Results/InputsSummary.vue';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import ReportModal from 'ist-skeleton-vue/src/components/Results/ReportModal.vue';
import SummaryItem from 'ist-skeleton-vue/src/components/Simulation/SummaryItem.vue';
import Table from 'ist-skeleton-vue/src/components/Simulation/Table.vue';

Papa.parsePromise = (file) => new Promise((complete, error) => {
  Papa.parse(file, {
    complete,
    error,
    skipEmptyLines: true,
    download: true,
    header: true,
  });
});

export default {
  components: {
    EditJobName,
    InputsSummary,
    Loader,
    ReportModal,
    SummaryItem,
    Table,
  },
  data() {
    return {
      // job data
      job: null,
      task: null,
      input: null,
      schedule: null,
      output: null,
      // media data
      baseUrl: null,
      containerSAS: null,
      // view data
      activeSchedule: null,
      isPdfLoading: false,
    };
  },
  computed: {
    administration() {
      if (this.input !== null) {
        return this.input.extravascular ? 'extravascular' : 'intravenous';
      }
      return '';
    },
    formulationLibrary() {
      if (this.input.drug === 'triptorelin') {
        return [
          { value: 'A', label: 'A. Microparticles, subcutaneous injection' },
          { value: 'B', label: 'B. Microtubules, subcutaneous injection' },
          { value: 'C', label: 'C. Microtubules, subcutaneous injection' },
          { value: 'D', label: 'D. Microparticles, subcutaneous injection' },
          { value: 'E', label: 'E. Microsphere, intramuscular injection' },
          { value: 'custom', label: 'custom' },
        ];
      }
      if (this.input.drug === 'leuprorelin') {
        return [
          { value: 'AA', label: 'Leuprorelin acetate for depot suspension' },
          { value: 'custom', label: 'custom' },
        ];
      }
      return { value: 'custom', label: 'custom' };
    },
    formulation() {
      if (this.input == null) {
        return '';
      }
      if (this.input.formulation === 'custom') {
        return 'custom';
      }
      return this.formulationLibrary.find((formulation) => formulation.value === this.input.formulation).label;
    },
    dosingUploadHeaders() {
      if (this.schedule !== null && this.schedule.length > 0 && this.schedule[0].tinf !== undefined) {
        return ['group', 'time', 'amt', 'tinf'];
      }
      return ['group', 'time', 'amt'];
    },
    dosingUploadLabels() {
      if (this.schedule !== null && this.schedule.length > 0 && this.schedule[0].tinf !== undefined) {
        return ['Schedule ID', 'Day of injection', 'Amount (mg)', 'Infusion duration (days)'];
      }
      return ['Schedule ID', 'Day of injection', 'Amount (mg)'];
    },
  },
  async mounted() {
    try {
      await this.fetchJob();
      await this.fetchMediaInfo();
      await this.fetchSchedule();
      if (this.task.status === 'success') {
        await this.fetchOutputJson();
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async fetchJob() {
      const response = await this.$apollo.query({
        query: gql`
          query getJob($product_name: ProductName!, $job_id: ID!) {
            job: getJob(product_name: $product_name, job_id: $job_id) {
              _id
              name
              status
              created_at
              tasks {
                id: _id
                input
                status
                started_at
                finished_at
              }
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
        },
      });
      this.job = response.data.job;
      [this.task] = this.job.tasks;
      this.input = this.task.input;
    },
    async fetchMediaInfo() {
      const response = await this.$apollo.query({
        query: gql`
          query getAccessInfo($product_name: ProductName!, $job_id: ID!, $task_id: ID) {
            info: getAccessInfo(product_name: $product_name, job_id: $job_id, task_id: $task_id) {
              baseURL
              containerSAS
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
          task_id: this.task.id,
        },
      });
      this.baseUrl = response.data.info.baseURL;
      this.containerSAS = response.data.info.containerSAS;
      this.resultsUrl = `${this.baseUrl}results/`;
    },
    async fetchSchedule() {
      const scheduleUrl = `${this.baseUrl}schedule.csv?${this.containerSAS}`;
      const schedule = await Papa.parsePromise(scheduleUrl);
      this.schedule = schedule.data;
    },
    async fetchOutputJson() {
      const output = this.resultsURL('castr_subj_tab_json.json');
      const response = await fetch(output);
      const json = await response.json();
      const tableData = Object.entries(json).map((el) => ({
        id: el[0],
        schedule: el[1],
      }));
      this.output = tableData;
      this.activeSchedule = tableData[0].id;
    },
    resultsURL(filename) {
      return `${this.baseUrl}results/${filename}?${this.containerSAS}`;
    },
    handleJobNameEdit(newJobName) {
      this.job.name = newJobName;
    },
    async downloadPdf(userContent) {
      this.isPdfLoading = true;
      // refresh token for images
      await this.fetchMediaInfo();
      // shortcut
      const { input } = this;
      // prepare tables
      const absorptionHeadersZero = ['', 'D~inf~ (d)', 'F~inf~', 't~lag,inf~ (d)'];
      const absorptionRowsZero = [['Population', input.Dinf, input.Finf, input.tlaginf], ['IIV (%)', '', '', '']];
      const absorptionHeadersFirst = ['']
        .concat(input.transit_cmpt1 ? ['K~A1~ (1/d)', 'n~1~', 'mt~1~ (d)', 'F~1~'] : ['K~A1~ (1/d)', 't~lag1~ (d)', 'F~1~'])
        .concat(input.transit_cmpt2 ? ['K~A2~ (1/d)', 'n~2~', 'mt~2~ (d)', 'F~2~'] : ['K~A2~ (1/d)', 't~lag2~ (d)', 'F~2~']);
      const absorptionRowsFirst = [
        ['Population']
          .concat(input.transit_cmpt1 ? [input.KA1, input.n1, input.mt1, input.F1] : [input.KA1, input.tlag1, input.F1])
          .concat(input.transit_cmpt2 ? [input.KA2, input.n2, input.mt2, input.F2] : [input.KA2, input.tlag2, input.F2]),
        ['IIV (%)']
          .concat(input.transit_cmpt1 ? [input.IIV_KA1, '', '', ''] : [input.IIV_KA1, '', ''])
          .concat(input.transit_cmpt2 ? [input.IIV_KA2, '', '', ''] : [input.IIV_KA2, '', '']),
      ];
      const absorptionTableZero = {
        header: absorptionHeadersZero,
        rows: absorptionRowsZero,
        caption: 'Zero-order absorption parameters',
      };
      const absorptionTableFirst = {
        header: absorptionHeadersFirst,
        rows: absorptionRowsFirst,
        caption: 'First-order absorption parameters',
      };
      const dispositionTable = {
        header: ['', 'CL (L/d)', 'V (L)', 'Q~1~ (L/d)', 'V~1~ (L)', 'Q~2~ (L/d)', 'V~2~ (L)'],
        rows: [
          ['Population', input.CL, input.V, input.Q1, input.V1, input.Q2, input.V2],
          ['IIV (%)', input.IIV_CL, input.IIV_V, input.IIV_Q1, input.IIV_V1, input.IIV_Q2, input.IIV_V2],
        ],
        caption: 'Disposition parameters',
      };
      const pharmacodynamicsTable = {
        header: ['', 'D~R_50~', 'TST~0~ (ng/mL)', 'k~IN~ (ng/mL/d)', 'k~D_R~ (1/d)', 'AGN', 'k~D_T~ (1/d)', '$\\gamma$'],
        rows: [
          ['Population', input.E50, input.TST0, input.KIN, input.KREO, input.AGN, input.KDTT, input.GAM1],
          ['IIV (%)', input.IIV_EC50, input.IIV_TST0, input.IIV_KIN, input.IIV_KREO, input.IIV_AGN, input.IIV_KDTT, '-'],
        ],
        caption: 'System parameters',
      };
      const schedulesTable = {
        header: ['Schedule ID', 'Day of injection', 'Amount (mg)'],
        caption: 'Schedules',
      };
      if (this.schedule !== null && this.schedule.length > 0 && this.schedule[0].tinf !== undefined) {
        schedulesTable.header.push('Infusion duration (d)');
        schedulesTable.rows = this.schedule.map((sch) => [sch.group, sch.time, sch.amt, sch.tinf]);
      } else {
        schedulesTable.rows = this.schedule.map((sch) => [sch.group, sch.time, sch.amt]);
      }
      /* const castrationTable = {
        header: ['Schedule'].concat(this.output[0].schedule.map((col) => `Day ${col.Day}`)),
        rows: this.output.map((series) => [series.id].concat(series.schedule.map((col) => col.Percentage))),
        caption: 'Percentage of castrated subjects for every scheduling',
      }; */
      const castrationTable = {
        header: ['Day'].concat(this.output.map((series) => `Schedule ${series.id}`)),
        caption: `Percentage of virtual patients reaching testosterone plasma concentration levels &lt; ${input.threscastr} ng/mL every four weeks until the end of study`,
      };
      const castrationRows = [];
      for (let i = 0; i < this.output[0].schedule.length; i += 1) {
        const castrationRow = [this.output[0].schedule[i].Day];
        for (let j = 0; j < this.output.length; j += 1) {
          castrationRow.push(this.output[j].schedule[i].Percentage);
        }
        castrationRows.push(castrationRow);
      }
      castrationTable.rows = castrationRows;
      // prepare plots
      const concentrationCastrationPlots = [];
      this.output.forEach((outp) => {
        const scheduleId = outp.id;
        concentrationCastrationPlots.push({
          title: `### Plots for Schedule ${scheduleId}`,
          url: this.resultsURL(`drug_conc${scheduleId}.png`),
          caption: `Drug plasma concentration for schedule ${scheduleId}`,
        });
        concentrationCastrationPlots.push({
          url: this.resultsURL(`test_conc${scheduleId}.png`),
          caption: `Testosterone plasma concentration for schedule ${scheduleId}`,
        });
        concentrationCastrationPlots.push({
          url: this.resultsURL(`castr_subj${scheduleId}.png`),
          caption: `Percentage of patients achieving chemical castration for schedule ${scheduleId}`,
        });
      });
      // user content
      const userContentTokens = [];
      Object.keys(userContent).forEach((key) => {
        userContentTokens.push(
          { key, type: 'string', data: userContent[key] },
        );
      });
      // request PDF
      const response = await this.$api.call('POST', 'api/pdf-export-func', {
        header: {
          Accept: 'application/json',
        },
        responseType: 'blob',
        data: {
          template: 'gnrh',
          tokens: [
            ...userContentTokens,
            { key: 'title', type: 'string', data: this.job.name },
            { key: 'date', type: 'string', data: this.$filters.formatDate(this.task.finished_at) },
            { key: 'drug', type: 'string', data: this.capitalise(input.drug) },
            { key: 'formulation', type: 'string', data: this.formulation },
            { key: 'administration', type: 'string', data: this.administration },
            { key: 'absorptionTableZero', type: 'table', data: absorptionTableZero },
            { key: 'absorptionTableFirst', type: 'table', data: absorptionTableFirst },
            { key: 'dispositionTable', type: 'table', data: dispositionTable },
            { key: 'KDB', type: 'string', data: input.KDB },
            { key: 'IIV_KDB', type: 'string', data: input.IIV_KDB == null ? '-' : input.IIV_KDB },
            { key: 'pharmacodynamicsTable', type: 'table', data: pharmacodynamicsTable },
            { key: 'nsubj', type: 'string', data: input.nsubj },
            { key: 'threscastr', type: 'string', data: input.threscastr },
            { key: 'Tmax', type: 'string', data: input.Tmax },
            { key: 'schedulesTable', type: 'table', data: schedulesTable },
            { key: 'castrationTable', type: 'table', data: castrationTable },
            { key: 'concentrationCastrationPlots', type: 'figures', data: concentrationCastrationPlots },
          ],
        },
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'PCa_GnRH_Agonists_Report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.isPdfLoading = false;
    },
    capitalise(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<style scoped>
.no-space-bottom {
  margin-bottom: 0.1em !important;
}
.icon-with-margin {
  margin-right: 0.5em;
}
.tabs-with-space {
  margin-top: 3em;
  margin-bottom: 2em;
}
.figure-with-space {
  max-width: 80%;
  margin: auto;
  margin-top: 1.5em;
  margin-bottom: 3em;
}
</style>
